<template>
<div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Profit Margin</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Form class="row" v-slot="{ meta }" @submit="update">
                <div class="col-md-6">
                  <Field v-model="margin" type="text" class="form-control" rules="required" name="margin" />
                  <ErrorMessage name="margin" class="text-danger" />
                </div>
                <div class="col-md-6">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="!(meta.valid && meta.dirty)"
                    >
                        Сохранить
                    </button>
                </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { getMargin, updateMargin } from '@/services/dashboard.service'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  data: () => ({
    margin: null
  }),
  components: {
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    async fetchMargin () {
      const margin = await getMargin()
      this.margin = margin.data[0].margin
    },
    async update (values) {
      const formData = new FormData()

      formData.append('margin', values.margin)
      await updateMargin(formData)
    }
  },
  async created () {
    await this.fetchMargin()
  }
}
</script>
