import axios from '@/plugins/axios'

export const all = async () => {
  const { data: response } = await axios.get('/admin/dashboard/kpi/nav-bar')

  return response
}

export const users = async () => {
  const { data: response } = await axios.get('/admin/dashboard/kpi/users')

  return response
}

export const orders = async () => {
  const { data: response } = await axios.get('/admin/dashboard/kpi/orders')
  return response
}

export const getMargin = async () => {
  const { data: response } = await axios.get('/admin/margin')
  return response
}

export const updateMargin = async (formData) => {
  const { data: response } = await axios.post('/admin/margin', formData)
  return response
}
